var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer__container" }, [
      _c(
        "div",
        { staticClass: "info-footer" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "info-footer__logo footer-logo",
              attrs: { to: _vm.localePath("/") },
            },
            [
              _vm.theme === "dark"
                ? _c("nuxt-img", {
                    staticClass: "footer-logo__img-dark",
                    attrs: {
                      src: "/design/footer-logo-dark.svg",
                      alt: _vm.$t("common.logo_alt_footer"),
                      title: _vm.$t("common.logo_title_footer"),
                      loading: "lazy",
                    },
                  })
                : _c("nuxt-img", {
                    staticClass: "footer-logo__img-light",
                    attrs: {
                      src: "/design/footer-logo-light.svg",
                      alt: _vm.$t("common.logo_alt_footer"),
                      title: _vm.$t("common.logo_title_footer"),
                      loading: "lazy",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "nuxt-link",
            {
              staticClass: "info-footer__logo footer-logo footer-logo_mobile",
              attrs: { to: _vm.localePath("/") },
            },
            [
              _vm.theme === "dark"
                ? _c("nuxt-img", {
                    staticClass: "footer-logo__img-dark",
                    attrs: {
                      src: "/design/footer-logo-dark.svg",
                      alt: _vm.$t("common.logo_alt_footer"),
                      title: _vm.$t("common.logo_title_footer"),
                      loading: "lazy",
                    },
                  })
                : _c("nuxt-img", {
                    staticClass: "footer-logo__img-light",
                    attrs: {
                      src: "/design/footer-logo-light.svg",
                      alt: _vm.$t("common.logo_alt_footer"),
                      title: _vm.$t("common.logo_title_footer"),
                      loading: "lazy",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "info-footer__paragraph" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("common.footer_desc")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "copyright" }, [
            _vm._v(
              "\n        ©\n        " + _vm._s(_vm.currentYear()) + "\n        "
            ),
            _c("span", [
              _vm._v(
                "\n          " + _vm._s(_vm.$config.appName) + "\n        "
              ),
            ]),
            _vm._v(
              ".\n        " + _vm._s(_vm.$t("common.all_rights")) + ".\n      "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "nav",
        { class: { "footer-navigation": true, _active: _vm.mobileInfoOpened } },
        [
          _c("div", { staticClass: "footer-navigation__list" }, [
            _c("h3", { staticClass: "footer-navigation__title" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("menu.products")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.ranking")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/exchanges") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.exchanges")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/defi") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.defi")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/play-to-earn") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.p2e")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/ico-events-calendar") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.ico_events")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/nft") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.nft_top")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer-navigation__list" }, [
            _c("h3", { staticClass: "footer-navigation__title" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("menu.company")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/about") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.about")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/terms") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.terms")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/policy") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.policy")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer-navigation__list" }, [
            _c("h3", { staticClass: "footer-navigation__title" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("common.support")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/support") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.contact_support")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/faq") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.faq")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer-navigation__el" },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-navigation__link",
                    attrs: { to: _vm.localePath("/glossary") },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("menu.glossary")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer-social" }, [
        _c("h3", { staticClass: "footer-social__title" }, [
          _vm._v("\n        " + _vm._s(_vm.$t("menu.socials")) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer-social__list" }, [
          _vm.contactsData.telegram !== null &&
          _vm.contactsData.telegram.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.telegram,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bx bxl-telegram" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactsData.telegram_chat !== null &&
          _vm.contactsData.telegram_chat.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.telegram_chat,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [
                  _c("i", { staticClass: "bx bxl-telegram" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.chat")) +
                        "\n          "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactsData.discord !== null &&
          _vm.contactsData.discord.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.discord,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bx bxl-discord-alt" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactsData.vk !== null && _vm.contactsData.vk.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.vk,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bx bxl-vk" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactsData.facebook !== null &&
          _vm.contactsData.facebook.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.facebook,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bx bxl-facebook" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactsData.twitter !== null &&
          _vm.contactsData.twitter.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.twitter,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bx bxl-twitter" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactsData.instagram !== null &&
          _vm.contactsData.instagram.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.instagram,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bx bxl-instagram" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contactsData.youtube !== null &&
          _vm.contactsData.youtube.length > 0
            ? _c(
                "a",
                {
                  staticClass: "footer-social__el",
                  attrs: {
                    href: _vm.contactsData.youtube,
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bx bxl-youtube" })]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "copyright" }, [
          _vm._v(
            "\n        ©\n        " + _vm._s(_vm.currentYear()) + "\n        "
          ),
          _c("span", [
            _vm._v("\n          " + _vm._s(_vm.$config.appName) + "\n        "),
          ]),
          _vm._v(
            ".\n        " + _vm._s(_vm.$t("common.all_rights")) + ".\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            class: { "footer-spoiler": true, _active: _vm.mobileInfoOpened },
            attrs: { "aria-label": _vm.$t("common.return_up") },
            on: { click: _vm.toggleSpoiler },
          },
          [_c("i", { staticClass: "bx bxs-up-arrow" })]
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        { class: { "footer-paragraph": true, _active: _vm.mobileInfoOpened } },
        [_vm._v("\n      " + _vm._s(_vm.$t("common.footer_desc")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }