<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="info-footer">
        <nuxt-link :to="localePath('/')" class="info-footer__logo footer-logo">
          <nuxt-img
            v-if="theme === 'dark'"
            src="/design/footer-logo-dark.svg"
            :alt="$t('common.logo_alt_footer')"
            :title="$t('common.logo_title_footer')"
            class="footer-logo__img-dark"
            loading="lazy"
          />
          <nuxt-img
            v-else
            src="/design/footer-logo-light.svg"
            :alt="$t('common.logo_alt_footer')"
            :title="$t('common.logo_title_footer')"
            class="footer-logo__img-light"
            loading="lazy"
          />
        </nuxt-link>
        <nuxt-link :to="localePath('/')" class="info-footer__logo footer-logo footer-logo_mobile">
          <nuxt-img
            v-if="theme === 'dark'"
            src="/design/footer-logo-dark.svg"
            :alt="$t('common.logo_alt_footer')"
            :title="$t('common.logo_title_footer')"
            class="footer-logo__img-dark"
            loading="lazy"
          />
          <nuxt-img
            v-else
            src="/design/footer-logo-light.svg"
            :alt="$t('common.logo_alt_footer')"
            :title="$t('common.logo_title_footer')"
            class="footer-logo__img-light"
            loading="lazy"
          />
        </nuxt-link>
        <p class="info-footer__paragraph">
          {{ $t('common.footer_desc') }}
        </p>
        <p class="copyright">
          &copy;
          {{ currentYear() }}
          <span>
            {{ $config.appName }}
          </span>.
          {{ $t('common.all_rights') }}.
        </p>
      </div>
      <nav :class="{'footer-navigation': true, '_active': mobileInfoOpened}">
        <div class="footer-navigation__list">
          <h3 class="footer-navigation__title">
            {{ $t('menu.products') }}
          </h3>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/')" class="footer-navigation__link">
              {{ $t('menu.ranking') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/exchanges')" class="footer-navigation__link">
              {{ $t('menu.exchanges') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/defi')" class="footer-navigation__link">
              {{ $t('menu.defi') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/play-to-earn')" class="footer-navigation__link">
              {{ $t('menu.p2e') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/ico-events-calendar')" class="footer-navigation__link">
              {{ $t('menu.ico_events') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/nft')" class="footer-navigation__link">
              {{ $t('menu.nft_top') }}
            </nuxt-link>
          </div>
        </div>
        <div class="footer-navigation__list">
          <h3 class="footer-navigation__title">
            {{ $t('menu.company') }}
          </h3>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/about')" class="footer-navigation__link">
              {{ $t('menu.about') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/terms')" class="footer-navigation__link">
              {{ $t('common.terms') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/policy')" class="footer-navigation__link">
              {{ $t('common.policy') }}
            </nuxt-link>
          </div>
        </div>
        <div class="footer-navigation__list">
          <h3 class="footer-navigation__title">
            {{ $t('common.support') }}
          </h3>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/support')" class="footer-navigation__link">
              {{ $t('menu.contact_support') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/faq')" class="footer-navigation__link">
              {{ $t('menu.faq') }}
            </nuxt-link>
          </div>
          <div class="footer-navigation__el">
            <nuxt-link :to="localePath('/glossary')" class="footer-navigation__link">
              {{ $t('menu.glossary') }}
            </nuxt-link>
          </div>
        </div>
      </nav>
      <div class="footer-social">
        <h3 class="footer-social__title">
          {{ $t('menu.socials') }}
        </h3>
        <div class="footer-social__list">
          <a
            v-if="contactsData.telegram !== null && contactsData.telegram.length > 0"
            class="footer-social__el"
            :href="contactsData.telegram"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-telegram"></i>
          </a>
          <a
            v-if="contactsData.telegram_chat !== null && contactsData.telegram_chat.length > 0"
            class="footer-social__el"
            :href="contactsData.telegram_chat"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-telegram"></i>
            <span class="label">
              {{ $t('common.chat') }}
            </span>
          </a>
          <a
            v-if="contactsData.discord !== null && contactsData.discord.length > 0"
            class="footer-social__el"
            :href="contactsData.discord"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-discord-alt"></i>
          </a>
          <a
            v-if="contactsData.vk !== null && contactsData.vk.length > 0"
            class="footer-social__el"
            :href="contactsData.vk"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-vk"></i>
          </a>
          <a
            v-if="contactsData.facebook !== null && contactsData.facebook.length > 0"
            class="footer-social__el"
            :href="contactsData.facebook"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-facebook"></i>
          </a>
          <a
            v-if="contactsData.twitter !== null && contactsData.twitter.length > 0"
            class="footer-social__el"
            :href="contactsData.twitter"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-twitter"></i>
          </a>
          <a
            v-if="contactsData.instagram !== null && contactsData.instagram.length > 0"
            class="footer-social__el"
            :href="contactsData.instagram"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-instagram"></i>
          </a>
          <a
            v-if="contactsData.youtube !== null && contactsData.youtube.length > 0"
            class="footer-social__el"
            :href="contactsData.youtube"
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <i class="bx bxl-youtube"></i>
          </a>
        </div>
        <p class="copyright">
          &copy;
          {{ currentYear() }}
          <span>
            {{ $config.appName }}
          </span>.
          {{ $t('common.all_rights') }}.
        </p>

        <button :class="{'footer-spoiler': true, '_active': mobileInfoOpened}" :aria-label="$t('common.return_up')" @click="toggleSpoiler">
          <i class="bx bxs-up-arrow"></i>
        </button>
      </div>
      <p :class="{'footer-paragraph': true, '_active': mobileInfoOpened}">
        {{ $t('common.footer_desc') }}
      </p>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterComponent',
  data() {
    return {
      mobileInfoOpened: false
    }
  },
  computed: {
    ...mapGetters(['theme']),
    ...mapGetters('seos', [
      'contacts'
    ]),
    contactsData() {
      return this.contacts !== null
        ? this.contacts
        : {
            description: '',
            email: '',
            phone: '',
            youtube: '',
            facebook: '',
            instagram: '',
            telegram: '',
            telegram_chat: '',
            vk: '',
            discord: '',
            twitter: ''
          }
    }
  },
  async beforeMount() {
    try {
      if (this.contacts === null) {
        await this.$store.dispatch('seos/fetchContacts')
      }
    } catch (err) {
      console.error(err)
    }
  },
  methods: {
    currentYear() {
      const date = new Date()
      return date.getFullYear()
    },
    toggleSpoiler() {
      this.mobileInfoOpened = !this.mobileInfoOpened
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/common/footer.css');
</style>
